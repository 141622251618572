<template>
    <header class="shadow-sm">
        <b-container class="header">
        <b-row align-h="between" align-v="center">
            <b-col cols="4" align-self="center"><router-link to="/home"><img src="../assets/BPP.svg" id="logo" /></router-link></b-col>
            <b-col cols="4" align-self="center" class="text-right">
            {{ $root.name }}
            <span class="logout" @click="callModal">
                <b-icon icon="door-open-fill" variant="secondary"></b-icon>
            </span>
            </b-col>
        </b-row>
        </b-container>
    </header>
</template>
<script>
export default {
  name: "Header",
  async created () {
    this.$root.name = sessionStorage.name;
  },
  data() {
    return {}
  },
  methods: {
    callModal() {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair da conta?", {
          title: "Sair da Conta",
          size: "lg",
          buttonSize: "lg",
          okVariant: "danger",
          okTitle: "SIM",
          cancelTitle: "NÂO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.logout();
          }
        })
        .catch((err) => {
          this.$root.$emit('notify', { label: err, time: 10, type: 'danger'});
        });
    },
    logout() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("pessoaId");
      sessionStorage.removeItem("name");
      this.$root.pessoaId = false;
      this.$root.token = false;
      this.$router.push('/');
    }
  }
};
</script>
<style scoped>
#logo {
  height: 30px;
}
header {
  width: 100vw;
  height: 60px;
  margin-bottom: 20px;
}
.header {
  width: 150%;
  position: relative;
  top: 14px;
}
.logout {
  margin-left: 5px;
  width: 20px;
  background-color: #efefef;
  border-radius: 50%;
  padding: 10px;
  padding-left: 13px;
  padding-right: 13px;
  cursor: pointer;
}
</style>